<template>
    <!-- v-if="$store.state.user.current_user.part==1||$store.state.user.current_user.part==2||$store.state.user.current_user.part==3||$store.state.user.current_user.part==4"-->
    <div class="pc_home">
        <!--通用头部-->
        <div class="main_top">
            <img src="@/assets/img/logo.png" alt="logo">
            <h1 @dblclick="dbclick" >上海援青项目信息化管理平台</h1>
            <div class="to_link" @click="toLink" v-if="this.$store.state.user.current_user?.sz_guoluo?.power?.look == 1">
                数字果洛
            </div>
            

            <el-popover
                placement="bottom-end"
                trigger="hover"
                popper-class="user_info_pop"
                width="60"
            >
                <div class="user_info" slot="reference">
                    <span class="user">登录人：{{ $store.state.user.current_user.nickname }}</span>
                    <i class="el-icon-arrow-down more" ></i>
                </div>

                <ul>
                    <li @click="logout">退出</li>
                </ul>
            </el-popover>
        </div>
        <div class="main_bottom">
            <!--左侧边栏-->
            <ul class="sidebar">
                <li :class="{active:$route.path.indexOf(v.path)!==-1}" v-for="(v,k) in sidebar" :key="k"
                    @click="sidebarChange(v)"
                    v-if="v.model_name==='task_power'||$store.state.user.current_user[v.model_name]?.power?.look">
                    {{ v.name }}
                </li>
            </ul>
            <!--主体内容-->
            <div class="main_box">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import utils from '@/utils/public.js'
import container from '@/utils/container.js'

export default {
    data() {
        return {
            sidebar: [
                {name: '首页', route_name: 'index', model_name: 'first_page_power', path: '/pc/index'},
                {name: '市级项目管理', route_name: 'project', model_name: 'shi_project_project_power', path: '/pc/project'},
                {name: '市级项目资金', route_name: 'money', model_name: 'fund_project_power', path: '/pc/money'},
                {name: '三交活动报备', route_name: 'three_cross', model_name: 'three_cross_power', path: '/pc/three_cross'},
                {name: '区级项目管理', route_name: 'report', model_name: 'qu_project_power', path: '/pc/report'},
                {name: '自筹项目管理', route_name: 'unplanned', model_name: 'zichou_project_power', path: '/pc/unplanned'},
                {name: '任务管理', route_name: 'task', model_name: 'task_power', path: '/pc/task'},
                // {name: '项目审计', route_name: 'audit', model_name: 'shenji_project_power', path: '/pc/audit'},
                // {name: '委托服务管理', route_name: 'check', model_name: 'weituo_power', path: '/pc/check'},
                // {name: '项目评估', route_name: 'assess', model_name: 'pinggu_project_power', path: '/pc/assess'},
                // {name: '储备项目', route_name: 'reserve', model_name: 'chubei_project_power', path: '/pc/reserve'},
                // {name: '统计分析', route_name: 'statistics', model_name: 'statistics_power', path: '/pc/statistics'},
                {name: '权限管理', route_name: 'authority', model_name: 'power_power', path: '/pc/authority'},
                {name: '县域概况', route_name: 'quxiangaikuo', model_name: 'xygk_power', path: '/pc/quxiangaikuo'},
            ],
            // showSidebar:[],//根据权限显示出来的左侧导航.

            num:0
        };
    },
    created() {
        if (utils.getUrlKey('system')) {
            this.$store.commit('user/SETTOKEN', '698d51a19d8a121ce581499d7b701668');
            let user_info = {
                nickname: '唐春娅',
                part: 1,
                area: '',
                all_project: 1,
                fuze_users: '',
                userid: '265659300821760400',//265659300821760400
                dignity: 3,
                department: '70447212',//394186677 第三方人员部门id
                first_page_power: {
                    power: {
                        look: 1,
                    },
                    area: '',
                },
                task_power: {
                    power: {
                        look: 1,
                        register: 1,
                        appraise: 1,
                    }
                },
                shi_project_project_power: {
                    power: {
                        look: 1,
                        month_form: 1,
                        edit: 1,
                        upload: 1,
                        change: 1,
                        stop: 1,
                        delete: 1,
                    },
                    area: -1,
                },
                qu_project_power: {
                    power: {
                        look: 1,
                        edit: 1
                    },
                    area: 1
                },
                zichou_project_power: {
                    power: {
                        look: 1,
                        edit: 1
                    },
                    area: 2
                },
                fund_project_power: {
                    power: {
                        look: 1,
                        edit: 1
                    },
                    area: -1
                },
                shenji_project_power: {
                    power: {
                        look: 1,
                        edit: 1
                    },
                    area: -1
                },
                weituo_power: {
                    power: {
                        look: 1,
                        edit: 1
                    },
                    area: -1
                },
                pinggu_project_power: {
                    power: {
                        look: 1,
                        edit: 1
                    },
                    area: 5
                },
                chubei_project_power: {
                    power: {
                        look: 1,
                        edit: 1,
                        up: 0,
                    },
                    area: -1
                },
                statistics_power: {
                    power: {
                        look: 1,
                        edit: 1
                    },
                    area: -1
                },
                power_power: {
                    power: {
                        look: 1,
                        edit: 1
                    },
                },
                three_cross_power: {
                    power: {
                        look: 1,
                        edit: 1,
                    },
                    area: '',
                },
                sz_guoluo:{
                    power:{
                        look: 1
                    }
                },
                xygk_power:{
                    power:{
                        look:1
                    },
                    area:-1
                }
            };
            this.$store.commit('user/SETCURRENTUSER', user_info); //090133404330725926  265659300821760400
            this.$store.commit('user/SETPROJECTEDIT', {
                "id": 1,
                "project_edit": '1',
                "create_time": "2020-05-19 10:21:28",
                "update_time": "2020-05-19 10:21:28",
                "status": 1
            });
        } else {
           
        }


        // console.log(JSON.stringify(user_info.power_power),'power_power的权限配置 修改一下重新打包');

        //上传时需解除注释
        let user_info = this.$store.state.user.current_user;
        let department = user_info.department ? user_info.department.split(',') : [];
        if (department.indexOf('394186677') === -1) {
            this.$set(user_info, 'type', 1);//内部人员
        } else {
            this.$set(user_info, 'type', 2);//第三方人员
        }
        let showSidebarName = [];//显示的左侧导航route_name合集
        this.sidebar.forEach(item => {
            if (item.model_name === 'first_page_power' && user_info[item.model_name]?.power?.look) {
                // this.showSidebar.push(item);
                showSidebarName.push('index_all');
                showSidebarName.push('index_unplanned');
            } else if (item.model_name === 'task_power') {
                // this.showSidebar.push(item);
                if (user_info[item.model_name]?.power?.look) {
                    showSidebarName.push('task_all');
                    showSidebarName.push('task_static');
                } else {
                    showSidebarName.push('task_all');
                }
            } else if (user_info[item.model_name]?.power?.look) {
                // this.showSidebar.push(item);
                if (item.model_name === 'qu_project_power') {
                    showSidebarName.push('report_month');
                    showSidebarName.push('report_all');
                } else if (item.model_name === 'statistics_power') {
                    showSidebarName.push('statistics_all');
                    showSidebarName.push('statistics_unplan');
                } else {
                    showSidebarName.push(item.route_name);
                }
            }
        });

        if (showSidebarName.length > 0) {
            if (showSidebarName.indexOf(this.$route.name) === -1) {
                this.$router.replace({name: showSidebarName[0]});
            }
        } else {
            this.$message.error('您没有任何查看权限，请联系管理员添加!');
        }

        // if(this.showSidebar.length>0){
        //     if(showSidebarName.indexOf(this.$route.name)===-1){
        //         this.$router.replace({name:this.showSidebar[0].route_name});
        //     }
        // }else{
        //     this.$message.error('您没有任何查看权限，请联系管理员添加!')
        // }
        // if(this.$store.state.user.current_user.part==1){
        //     this.$router.replace({path:'/pc/index/all'});
        // } else if(this.$store.state.user.current_user.part==2){
        //     this.$router.replace({path:'/pc/index/all'});
        // } else if(this.$store.state.user.current_user.part==3){
        //     this.$router.replace({path:'/pc/money'});
        // } else if(this.$store.state.user.current_user.part==4){
        //     this.$router.replace({path:'/pc/project'});
        // }else{
        //     this.$message.error('您没有任何角色，请联系管理员添加!')
        // }
    },
    methods: {
        sidebarChange(v) {
            if (this.$route.path.indexOf(v.path) !== -1) return;
            this.$router.replace({name: v.route_name});
        },
        toLink() {
            api.home.GetOpenSign({}).then(res => {
                let timestamp = '';
                let sign = '';
                if (res.data) {
                    timestamp = res.data.timestamp || '';
                    sign = res.data.sign || '';
                }
                let url = `http://116.62.151.136:8033/#/pagemanage/Share/checkShareURL/vaieQj?timestamp=${timestamp}&sign=${sign}`;//http://jy1.eatuo.com:8090/#/pagemanage/Share/checkShareURL/iEveU3

                if (container.dd) {
                    dd.biz.util.openLink({
                        url, //要打开链接的地址
                        onSuccess: function (result) {
                            console.log('打开数据大屏成功');
                        },
                        onFail: function (err) {
                            console.log('打开数据大屏失败', err);
                        }
                    });
                } else if (container.browser) {
                    window.open(url);
                }
            });
        },
        async logout() {
            const msg = await api.login.LoginOut()
            if (msg.data.state == 1) {
                this.$message.success('退出登录！');
                localStorage.removeItem('token');
                this.$router.replace({ path: "/login" });
            }
        },
        dbclick() {
            this.num++ 
            if (this.num >=5) {
                this.$router.push({name: 'system_file_list'})
            }
        }

    }
};
</script>

<style scoped lang="less">
.pc_home {
    width: 100%;
    height: 100%;
    background: #f2f2f6;

    .main_top {
        display: flex;
        align-items: center;
        background: #fff;
        height: 56px;
        padding: 0 30px;
        border-bottom: 1px solid #ededee;

        img {
            flex: 0 0 42px;
        }

        h1 {
            flex: 1;
            min-width: 0;
            font-weight: bold;
            color: #18c485;
            padding-left: 10px;
            font-size: 33px;
        }

        .to_link {
            cursor: pointer;
            color: #3396fb;
            font-weight: 700;
            font-size: 31px;

            &:hover {
                text-decoration: underline;
            }
        }

        .user_info {
            cursor: pointer;
        }

        .user {
            margin-left: 20px;
            margin-right: 4px;
        }
    }

    .main_bottom {
        height: calc(100% - 57px);

        ul.sidebar {
            background: #fff;
            overflow-y: overlay;
            float: left;
            width: 160px;
            height: 100%;

            li {
                height: 50px;
                line-height: 50px;
                cursor: pointer;
                transition: all 0.3s;
                padding-left: 34px;
                font-size: 16px;

                &:hover {
                    color: #18c485;
                }

                &.active {
                    color: #18c485;
                    background: #d8fcef;
                    box-shadow: -4px 0 #43e0a7 inset;
                }
            }
        }

        .main_box {
            width: calc(100% - 160px);
            margin-left: 160px;
            padding: 12px 12px;
            height: 100%;
            box-sizing: border-box;
            overflow-x: overlay;
            //页面内公共样式
            /deep/ .main {
                min-width: 960px;
                background: #fff;
                height: 100%;
                border-radius: 10px;

                ul.tabs {
                    height: 46px;
                    line-height: 46px;
                    border-bottom: 1px solid #f2f2f2;
                    display: flex;

                    > li {
                        font-size: 16px;
                        margin-left: 32px;

                        &.tab {
                            transition: all 0.3s;
                            padding: 0 12px;
                            cursor: pointer;
                            text-align: center;

                            &:hover {
                                color: #18c485;
                            }

                            &.active {
                                color: #18c485;
                                box-shadow: 0 -3px #43e0a7 inset;
                            }
                        }
                    }
                }

                .container {
                    height: calc(100% - 52px);
                    padding: 0 32px;
                    display: flex;
                    flex-direction: column;

                    .search_filter {
                        padding: 10px 0 0 0;
                        display: flex;
                        flex-wrap: wrap;

                        > .item {
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            flex: 0 0 215px;
                            margin-right: 20px;

                            .left {
                                flex: 0 0 65px;
                                padding: 0 !important;
                            }
                        }

                        > .btn_item {
                            margin-bottom: 10px;
                            margin-right: 20px;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .table {
                        flex: 1;
                        min-height: 0;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        //分页
                        .pagination {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 50px;

                            .el-pagination {
                                //总数
                                span.el-pagination__total {
                                    margin-right: 140px;
                                    height: 24px;
                                }

                                //跳转
                                span.el-pagination__jump {
                                    margin-left: 140px;
                                    height: 24px;

                                    input.el-input__inner {
                                        width: 40px;
                                        height: 20px;
                                    }
                                }

                                button.btn-prev, button.btn-next {
                                    height: 24px;
                                }

                                ul.el-pager {
                                    li {
                                        min-width: 24px;
                                        margin-right: 20px;

                                        &:last-of-type {
                                            margin-right: 0;
                                        }

                                        &.number {
                                            height: 24px;
                                            line-height: 24px;
                                            font-weight: normal;

                                            &.active {
                                                background: #3396fb;
                                                color: #fff;
                                                border-radius: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="less">
.user_info_pop {
    min-width: 50px;
    padding: 6px;

    ul {
        display: flex;
        flex-direction: column;
        li {
            width: 100%;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #e5fdf5;
            }
        }
    }
}
</style>
